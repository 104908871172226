import React, {useCallback, useEffect} from "react";
import {Icon, Menu} from "antd";
import {withRouter} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {rolesSelector} from "../../../selectors";

export default withRouter(({history, location}) => {
    const roles = useSelector(rolesSelector, shallowEqual);
    return (
        <>
            <div className="logo"/>
            <Menu theme="dark" mode="inline" selectedKeys={location.pathname}>
                <Menu.Item key="/answers" onClick={() => history.replace("/answers")}>
                    <Icon type="user"/>
                    <span>Відповіді</span>
                </Menu.Item>
                <Menu.Item key="/sections" onClick={() => history.replace("/sections")}>
                    <Icon type="unordered-list" />
                    <span>Секції</span>
                </Menu.Item>
                <Menu.Item key="/lessons" onClick={() => history.replace("/lessons")}>
                    <Icon type="unordered-list" />
                    <span>Заняття</span>
                </Menu.Item>
                <Menu.Item key="/questions-types" onClick={() => history.replace("/questions-types")}>
                    <Icon type="ordered-list" />
                    <span>Типи запитань</span>
                </Menu.Item>
                <Menu.Item key="/excel" onClick={() => history.replace("/excel")}>
                    <Icon type="file-excel" />
                    <span>Excel</span>
                </Menu.Item>
                {/* {(roles.contains(ROLES.Admin)|| roles.contains(ROLES.ProRector))&& <Menu.Item key="100" onClick={() => history.replace("/lessons")}>
                    <Icon type="ordered-list"/>
                    <span>Заняття</span>
                </Menu.Item>} */}
            </Menu>
        </>
    );

})



const ROLES = {
    Admin: "Admin",
     Dean: "Dean",
    Department: "Department",
    Faculty: "Faculty",
    HeadOfDepartment: "HeadOfDepartment",
    ProRector: "Pro-rector",

};
