import {UPDATE_ANSWERS} from "./types";
const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_ANSWERS:
            const answers = {};
            Object.keys(action.payload).filter((key) =>
                answers[key] = action.payload[key]);
            return {
                ...state,
                ...answers
            };
        default:
            return state;
    }
}
