import axios from "axios";
import {notification} from "antd/es";
import {FETCH_ACTIVE_QUESTIONS} from "./types";

export const fetchSections = (items, individualPlan) => {
    const request = axios.get(`/questions/sections`, {
        params: {
            individualPlan, items
        }
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_ACTIVE_QUESTIONS, payload: data});
        })
            .catch(() => {
                notification["error"]({
                    message: "Error!"
                });
            });
    }
};
