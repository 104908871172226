import {Map} from "immutable";
import {FETCH_LESSON_INFO} from "./types";
const initialState = Map();

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_LESSON_INFO:
            return  Map(action.payload);
        default:
            return state;
    }
}
