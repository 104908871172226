import React from "react";

export default () =>
    <h3>
        Шановні студенти. Ця анкета допоможе нам з’ясувати
        якість викладання навчальних дисциплін. Ваші відповіді є
        конфіденційними. Ми дуже цінуємо Ваші коментарі, які допоможуть нам
        вдосконалити предмети та узгодити навчальні програми.
        Щоб досягнути мети будь ласка оцініть за 5-ти бальною шкалою якість
        викладання дисципліни за різними критеріями (1 (незадовільно) – 5
        (відмінно))
    </h3>
