import {List} from "immutable"
import {CREATE_QUESTION_TYPE, FETCH_QUESTIONS_TYPES, UPDATE_QUESTION_TYPE} from "./types";

const initialState = List();

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_QUESTIONS_TYPES:
            return List(action.payload);
        case UPDATE_QUESTION_TYPE:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case CREATE_QUESTION_TYPE:
            return state.insert(0, action.payload);
        default:
            return state;
    }
}
