import {applyMiddleware} from "redux";
import {logger} from "redux-logger";
import thunk from "redux-thunk";

let middleware = applyMiddleware(thunk);
if (process.env.NODE_ENV === 'development') {
    middleware =
        applyMiddleware(thunk, logger);
}
export default middleware;
