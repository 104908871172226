import {combineReducers} from "redux-immutable";
import questions from "../pages/Questions/reducer"
import questionsTypes from "../pages/QuestionsTypes/reducer"
import sections from "../pages/Sections/reducer"
import answers from "../pages/Answers/reducer"
import feedbackAnswers from "../pages/FeedbackForm/reducer"
import lessonInfo from "../pages/FeedbackForm/components/LessonInfo/reducer"
import feedbackQuestions from "../pages/FeedbackForm/components/IndividualPlan/reducer"
import lessons from "../pages/Lessons/reducer"
import teachers from "../pages/Teachers/reducer"
import authenticated from "../pages/Login/reducer"
import user from "../pages/User/reducer"
import roles from "../pages/Roles/reducer"
import studyYears from "../pages/StudyYear/reducer"

const rootReducer = combineReducers({
    questions,
    questionsTypes,
    sections,
    feedbackAnswers,
    answers,
    lessons,
    teachers,
    authenticated,
    user,
    studyYears,
    roles,
    lessonInfo,
    feedbackQuestions,
});
export default rootReducer;
