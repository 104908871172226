import React, {useState} from "react";
import Sidebar from "./Sidebar";
import Layout from "antd/es/layout";
import Icon from "antd/es/icon";
import "./styles.scss";
import AppPageHeader from "./AppHeader/AppPageHeader";
import AppHeader from "./AppHeader";

const {Content, Sider, Header, Footer} = Layout;

export default (props) => {

    
    
    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                style={{
                    overflow: 'auto', height: '100vh', position: 'fixed', left: 0,
                }}
            >
                <Sidebar/>
            </Sider>
            <Layout style={{marginLeft: `${collapsed ? "80px" : "200px"}`}}>
                <Header style={{background: '#fff', padding: 0, height: "auto"}}>
                    <Icon
                        className="trigger"
                        style={{fontSize: "1.5rem"}}
                        type={collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => setCollapsed(!collapsed)}
                    />
                    <AppHeader/>
                </Header>
                <AppPageHeader/>
                <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                    <div style={{padding: 24, background: '#fff', textAlign: 'center', minHeight: 360}}>
                        {props.children}
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>
                    © Система оцінювання якості викладання
                </Footer>
            </Layout>
        </Layout>
    )
}
