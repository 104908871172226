import {LOGIN} from "./types";

const initialState = false;

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return !state;
        default:
            return state;
    }
}
