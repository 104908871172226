import React from "react";
import {useField} from "formik";
import Icon from "antd/es/icon";
import TextArea from "antd/es/input/TextArea";

function FormTextarea({label, ...props}) {
    const [field, meta] = useField(props.name);
    return (
        <div className={"field"}>
            <TextArea {...field} {...props} rows={5}/>
            <label>{label}</label>
            {meta.touched && meta.error ? (
                <div className="error">
                    <Icon type="info-circle"/>
                    <span>{meta.error}</span>
                </div>
            ) : null}
        </div>
    )
}

export default FormTextarea;

