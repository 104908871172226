import {withRouter} from "react-router-dom";
import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchLessonInfo} from "./actions";
import {lessonInfoSelector} from "../../../../selectors";

export default withRouter(({history}) => {
    const dispatch = useDispatch();
    const lessonInfo = useSelector(lessonInfoSelector, shallowEqual);
    useEffect(() => {
        dispatch(fetchLessonInfo(history.location.search))
    }, [dispatch, history]);
    return (
        <>
            <h2 className={"text-center"}>Оцінка якості викладання</h2>
            <h4 className={"text-center"}>Предмет - {lessonInfo.get("courseName")}</h4>
            <h4 className={"text-center"}>Викладач - {lessonInfo.get("teacher")}</h4>
        </>
    )
});
