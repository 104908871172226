import {List} from "immutable"
import {CREATE_SECTION, FETCH_SECTIONS, UPDATE_SECTION} from "./types";

const initialState = List();

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SECTIONS:
            return List(action.payload);
        case UPDATE_SECTION:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case CREATE_SECTION:
            return state.insert(0, action.payload);
        default:
            return state;
    }
}
