import React, {lazy, Suspense} from 'react';
import './App.scss';
import {BrowserRouter as Router, Route} from "react-router-dom";

import FeedbackForm from "./pages/FeedbackForm"
import PrivateRoute from "./components/PrivateRoute";
const Sections = lazy(() => import('./pages/Sections'));
const QuestionsTypes = lazy(() => import('./pages/QuestionsTypes'));
const Questions = lazy(() => import('./pages/Questions'));
const Answers = lazy(() => import('./pages/Answers'));
const Lessons = lazy(() => import('./pages/Lessons'));
const Login = lazy(() => import('./pages/Login'));
const Excel = lazy(() => import('./pages/Excel'));

function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <div>
                    <Route exact path="/feedback" component={FeedbackForm}/>
                    <Route hasLayout={false} exact path="/" component={Login}/>
                    <PrivateRoute exact path="/questions" component={Questions}/>
                    <PrivateRoute exact path="/questions-types" component={QuestionsTypes}/>
                    <PrivateRoute exact path="/sections" component={Sections}/>
                    <PrivateRoute exact path="/answers" component={Answers}/>
                    <PrivateRoute exact path="/lessons" component={Lessons}/>
                    <PrivateRoute exact path="/Excel" component={Excel}/>

                </div>
            </Suspense>
        </Router>
    );
}

export default App;
