import {Map} from "immutable"
import {FETCH_ANSWERS,SET_DATA} from "./types";

const initialState = Map({
    teacher:null,
    studyYear:null,
    tree:Map()
});

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ANSWERS:
            return Map({
                teacher: state.get("teacher"),
                studyYear:state.get("studyYear"),
                tree:Map(action.payload)
            }); 
        case SET_DATA:
        return Map({
            teacher: action.payload.teacher,
            studyYear:action.payload.studyYear,
            tree:Map()
        }); 
        default:
            return state;
    }
}
