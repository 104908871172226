import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {feedbackAnswersSelector} from "../../../../selectors";
import {sendAnswers} from "../../actions";
import Button from "antd/es/button";

export default withRouter(({history}) => {
    const dispatch = useDispatch();
    const answers = useSelector(feedbackAnswersSelector, shallowEqual);
    useEffect(() => {
        dispatch(sendAnswers(answers, history.location.search));
    }, [dispatch, answers, history]);
    return <div className="steps-content text-center">
        <h3>Дякуємо за чесність!!!</h3>
        <Button onClick={() => { window.location.href = "https://rozklad.oa.edu.ua/feedback"}}>Повернутися назад на сторінку з опитуваннями</Button>
    </div>
})
