import React, {useCallback} from "react";
import "./styles.scss";
import Dropdown from "antd/es/dropdown";
import Icon from "antd/es/icon";
import {withRouter} from "react-router-dom";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {notification} from "antd/es";
import {userSelector} from "../../../selectors";
import AppMenu from "./AppMenu";
import Cookies from "js-cookie"
import {LOGIN} from "../../../pages/Login/types";

export default withRouter(({history}) => {
    const user = useSelector(userSelector, shallowEqual);
    const dispatch = useDispatch();
    const logout = useCallback(
        () => {
            notification["info"]({
                message: 'Info!',
                description: "You logout!",
            });
            history.push("/");
            Cookies.remove("/feedback");
            dispatch({type: LOGIN});
        }, [history,dispatch]);
    return (
        <div className={"anticon header"}>
            <Dropdown overlay={<AppMenu logout={logout}/>}>
                <div>
                    <img
                        src={user.get("imageUrl")}
                        alt={"user avatar"}/>
                    <span>{`${user.get("familyName")} ${user.get("givenName")}`}</span>
                    <Icon type="down" style={{fontSize: "1.5em"}}/>
                </div>
            </Dropdown>
        </div>
    )

})

