import axios from "axios";
import {notification} from "antd/es";
import { SEND_ANSWERS, UPDATE_ANSWERS} from "./types";




export const sendAnswers = (values, params) => {
    const request = axios.post(`/answers/send`, values, {params});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: SEND_ANSWERS});
        })
            .catch(() => {
                notification["error"]({
                    message: "Error!"
                });
            });
    }
};
export const updateAnswers = (values) => {
    return (dispatch) => {
        dispatch({type: UPDATE_ANSWERS, payload: values});
    }
};
