import React from "react";
import Icon from "antd/es/icon";
import {useField} from "formik";
import PropTypes from "prop-types";
import {Radio} from "antd";


function FormRadio({setFieldValue, count, ...props}) {
    const [field, meta] = useField(props.name);
    return (
        <div className={"field"}>
            {[1,2,3,4,5].map(number =>
                <Radio key={number} {...field} onChange={()=> setFieldValue(props.name,number)} checked={number === field.value}>{number}</Radio>
            )}

            {meta.error ? (
                <div className="error">
                    <Icon type="info-circle"/>
                    <span>{meta.error}</span>
                </div>
            ) : null}
        </div>

    )
}

export default FormRadio;

FormRadio.propTypes = {
    name: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired

};
FormRadio.defaultProps = {
    count: 5

};
