import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {feedbackQuestionsSelector} from "../../../../selectors";
import FeedbackQuestions from "../FeedbackQuestions";

export default ({sectionIndex, setSectionIndex, setCurrent}) => {
    const questions = useSelector(feedbackQuestionsSelector, shallowEqual);
    const last = questions.get("value").length - 1 === sectionIndex;
    const section = questions.get("value")[sectionIndex];
    if (!section) return <div>Loading...</div>;
    return (
        <div>
            Секція - <span className={"section_title"}>{section.title}</span>
    <div key={`section_${sectionIndex}`} className={"section"}>
           
            <FeedbackQuestions key={`questions_${sectionIndex}`}
                               setCurrent={setCurrent}
                               sectionIndex={sectionIndex}
                               last={last}
                               setSectionIndex={setSectionIndex}
                               questions={section.questions}/>
        </div>
        </div>
    )
}