import {Form, Formik} from "formik";
import React, {useCallback} from "react";
import Button from "antd/es/button";
import FormInput from "../../../../components/Form/FormInput";
import FormRadio from "../../../../components/Form/FormRadio";
import FeedbackFormSchema from "./FeedbackFormSchema";
import Radio from "antd/es/radio";
import Icon from "antd/es/icon";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {feedbackAnswersSelector} from "../../../../selectors";
import FormTextarea from "../../../../components/Form/FormTextarea";
import {updateAnswers} from "../../actions";
import {chooseOptions} from "../IndividualPlan";


export default ({questions, setCurrent, sectionIndex, setSectionIndex, last}) => {
    const dispatch = useDispatch();
    const answers = useSelector(feedbackAnswersSelector, shallowEqual);
    const submit = useCallback((values) => {
        dispatch(updateAnswers(values));
        if (last) {
            setCurrent(2);
            
        } else setSectionIndex(sectionIndex + 1);
    }, [setCurrent, last, dispatch, sectionIndex, setSectionIndex]);
    return (
        <div className={"individual-plan"}>
            <Formik
                initialValues={answers}
                onSubmit={submit}
                validateOnChange
                validationSchema={FeedbackFormSchema(questions)}
                render={({handleSubmit, setFieldValue,values, errors}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                {questions.map((question) => {
                                    switch (question.typeName) {
                                        case "input":
                                            return (<div className={"field"} key={question.id}>
                                                <p>{question.title}</p>
                                                <FormInput key={question.id}
                                                           name={question.name} type="text"
                                                           label={question.label}
                                                           placeholder={question.placeholder}/></div>);
                                        case "textarea":
                                            return (<div className={"field"} key={question.id}>
                                                <p>{question.title}</p>
                                                <FormTextarea key={question.id}
                                                              name={question.name} type="text"
                                                              label={question.label}
                                                              placeholder={question.placeholder}/>
                                            </div>);
                                        case "range":
                                            return <div className={"field"} key={question.id}>
                                                <p>{question.title}</p>
                                                <FormRadio key={question.id}
                                                           name={question.name}
                                                           setFieldValue={setFieldValue}/></div>;
                                        case "choose":
                                            return (<div className={"field"} key={question.id}>
                                                <p>{question.title}</p>
                                                <Radio.Group options={chooseOptions}
                                                             value={values[question.name]}
                                                             onChange={(e) =>
                                                                 setFieldValue(question.name, e.target.value)}/>
                                                {errors[question.name] ? (
                                                    <div className="error">
                                                        <Icon type="info-circle"/>
                                                        <span>{errors[question.name]}</span>
                                                    </div>
                                                ) : null}
                                            </div>);
                                        default:
                                            return null;
                                    }
                                })}
                            </div>


                            <div className={"submit"}>
                                <Button onClick={() => {
                                    if (sectionIndex === 0) setCurrent(0);
                                    else setSectionIndex(sectionIndex - 1)
                                }}>Назад</Button>
                                <Button type="primary"
                                        htmlType={"submit"}
                                        >
                                    {last ? "Надіслати результати" : "Далі"}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    )
}
