import {List} from "immutable"
import {INSERT_USER_ROLES} from "./types";

const initialState = List();

export default function (state = initialState, action) {
    switch (action.type) {
        case INSERT_USER_ROLES:
            return List(action.payload);
        default:
            return state;
    }
}
