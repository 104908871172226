import {FETCH_ACTIVE_QUESTIONS} from "./types";
import {Map} from "immutable";
const initialState = Map({key:"",value:[]});

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ACTIVE_QUESTIONS:
            return  Map(action.payload);
        default:
            return state;
    }
}

