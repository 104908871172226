import React, {useState} from "react";
import "./styles.scss";
import Gratitude from "./components/Gratitude";
import Welcome from "./components/Welcome"
import IndividualPlan from "./components/IndividualPlan";
import LessonInfo from "./components/LessonInfo"
import FeedbackSteps from "./components/FeedbackSteps"
import SectionQuestions from "./components/SectionQuestions"
export default () => {

    const [current, setCurrent] = useState(0);
    const [sectionIndex, setSectionIndex] = useState(0);


    return (
        <div className={"questions"}>
            <LessonInfo/>
            <FeedbackSteps current={current} sectionIndex={sectionIndex}/>
            {current === 0 && <div className="steps-content">
                <Welcome/>
                <IndividualPlan setCurrent={setCurrent}/>
            </div>}
            {current === 1 && <div className="steps-content">
                <SectionQuestions
                    sectionIndex={sectionIndex}
                    setSectionIndex={setSectionIndex}
                    setCurrent={setCurrent}
                />
            </div>}
            {current === 2 && <Gratitude/>}
        </div>
    )
}
