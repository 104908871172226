import {Form, Formik} from "formik";
import React, {useCallback} from "react";
import Radio from "antd/es/radio";
import IndividualPlanSchema from "./IndividualPlanSchema";
import Button from "antd/es/button";
import Icon from "antd/es/icon";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {feedbackAnswersSelector} from "../../../../selectors";
import {updateAnswers} from "../../actions";
import {fetchSections} from "./actions";

export const chooseOptions = [
    {label: 'Так', value: true},
    {label: 'Ні', value: false},
];

export default withRouter(({setCurrent, history}) => {
    const dispatch = useDispatch();
    const answers = useSelector(feedbackAnswersSelector, shallowEqual);
    const submit = useCallback((values) => {
        dispatch(updateAnswers(values));
        dispatch(fetchSections(history.location.search, values.individualPlan));
        setCurrent(1)
    }, [setCurrent, dispatch, history]);
    return (
        <div className={"individual-plan"}>
            <Formik
                initialValues={{
                    individualPlan: answers["individualPlan"],
                }}
                validationSchema={IndividualPlanSchema}
                onSubmit={submit}
                render={({handleSubmit, setFieldValue, values, errors}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className={"field"}>
                                <p>Ви вивчали предмет за індивідуальним планом (особлива форма навчання, самостійне
                                    вивчення навчальних дисциплін)?</p>
                                <Radio.Group options={chooseOptions}
                                             defaultValue={values.individualPlan}
                                             onChange={(e) =>
                                                 setFieldValue("individualPlan", e.target.value)}/>
                                {errors["individualPlan"] ? (
                                    <div className="error">
                                        <Icon type="info-circle"/>
                                        <span>{errors["individualPlan"]}</span>
                                    </div>
                                ) : null}
                            </div>


                            <div className={"submit"}>
                                <Button type="primary" htmlType={"submit"}>Далі</Button>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    )
})
