
export const questionsSelector = state => state.get("questions");
export const questionsTypesSelector = state => state.get("questionsTypes");
export const sectionsSelector = state => state.get("sections");
export const answersSelector = state => state.get("answers");
export const lessonsSelector = state => state.get("lessons");
export const teachersSelector = state => state.get("teachers");
export const authenticated = state => state.get("authenticated");
export const userSelector = state => state.get("user");
export const rolesSelector = state => state.get("roles");
export const studyYearsSelector = state => state.get("studyYears");
export const lessonInfoSelector = state => state.get("lessonInfo");
export const feedbackAnswersSelector = state => state.get("feedbackAnswers");
export const feedbackQuestionsSelector = state => state.get("feedbackQuestions");
export const excelSelector = state => state.get("excel");



