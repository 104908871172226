import {notification} from "antd";
import {FETCH_LESSON_INFO} from "./types";
import axios from "axios";

export const fetchLessonInfo = (params) => {
    const request = axios.get(`/questions/info`, {params});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LESSON_INFO, payload: data});
        })
            .catch(() => {
                notification["error"]({
                    message: "Error!"
                });
            });
    }
};
