import {Map} from "immutable"
import {INSERT_USER_INFO} from "./types";

const initialState = Map();

export default function (state = initialState, action) {
    switch (action.type) {
        case INSERT_USER_INFO:
            return Map(action.payload);
        default:
            return state;
    }
}
