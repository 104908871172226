import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {store} from "./store"
import 'antd/dist/antd.css';
import {Provider} from "react-redux";
import './index.scss';

import axios from "axios";
axios.defaults.baseURL = 'https://feedback.oa.edu.ua/api';

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
