import PageHeader from "antd/es/page-header";
import React from "react";
import {withRouter} from "react-router-dom";

export default withRouter(({location}) => {
    return (
        <PageHeader
            title={routesNameMap[location.pathname]}
        />
    )
})
const routesNameMap = {
    '/questions': 'Питання анкети',
    '/sections': 'Секції анкети',
    '/questions-types': 'Види запитань анкети',
    '/lessons': 'Заняття',
    '/answers': 'Відповіді на питання анкети',
    '/excel': 'Завантаження результатів анкетування в csv',
};
