import * as Yup from "yup";

function getField(fields) {
    const validators = {};
    fields.forEach(field => {
        switch (field.typeName) {
            case "range":
                validators[field.name] = getRangeValidator();
                break;
                case "choose":
                validators[field.name] = getChooseValidator();
                break;
            case "input":
                validators[field.name] = getInputValidator();
                break;
            case "textarea":
                validators[field.name] = getTextareaValidator();
                break;
            default:
                break;
        }
    });
    return validators;
}


export default props => Yup.object().shape(getField(props))

const getRangeValidator = () => Yup
    .number()
    .positive()
    .required("Обов'язкове поле!");
const getInputValidator = () => Yup
    .string()
    .required("Обов'язкове поле!");
const getTextareaValidator = () => Yup
    .string();
const getChooseValidator = () => Yup
    .string()
    .required("Обов'язкове поле!");

