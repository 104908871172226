import {Route, withRouter} from "react-router-dom";
import React, {useEffect} from "react";
import AppLayout from "../Layout";
import PropTypes from "prop-types";
import {shallowEqual, useSelector} from "react-redux";
import {authenticated,rolesSelector} from "../../selectors";
 import {notification} from "antd/es";
const PrivateRoute = ({component: Component, hasLayout, history, ...rest}) => {
    const logined = useSelector(authenticated, shallowEqual);
   
    /*useEffect(() => {
        if (history.location.pathname ==='/'||
            history.location.pathname ==='/feedback') {
        } else if (!logined) {
            history.replace("/");
            notification["error"]({
                message: 'Помилка!',
                description: "Ви не увійшли у систему",
            });
        }
    }, [logined, history]);*/
    return (
        <Route
            {...rest}
            render={props => {
                return hasLayout
                    ?
                    <AppLayout>
                        <Component {...props} />
                    </AppLayout> :
                    <Component {...props} />;
            }
            }
        />
    );
};

export default withRouter(PrivateRoute);

PrivateRoute.propTypes = {
    hasLayout: PropTypes.bool
};
PrivateRoute.defaultProps = {
    hasLayout: true
};
