import Steps from "antd/es/steps";
import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import { feedbackQuestionsSelector} from "../../../../selectors";

const {Step} = Steps;

export default ({current,sectionIndex}) => {
    const questions = useSelector(feedbackQuestionsSelector, shallowEqual);
    const last = questions.get("value").length - 1 === sectionIndex;
    return (
        <Steps current={current}>
            <Step key={"Привітання"} title={"Привітання"}/>
            {questions.get("key") && <Step key={2} title={questions.get("key")}/>}
            {last && <Step key={"Подяка"} title={"Подяка"}/>}
        </Steps>
    )
}
