import React from "react";
import Menu from "antd/es/menu";

export default ({logout}) => {
    return (
        <Menu>
            <Menu.Item key="1" onClick={logout}>Вихід</Menu.Item>
        </Menu>
    )
}

